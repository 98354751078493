import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.div`
  @media only screen and (min-width: ${props => props.theme.responsive.small}) {
    float: left;
    margin-right: 1.25rem;
    clear: both;
  }
  label {
    cursor: pointer;
    display: block;
    color: ${props => props.theme.colors.text.dark};
    opacity: 0.6;
    font: 900 12px/14px ${props => props.theme.fonts.body};
    text-transform: uppercase;
  }
  span {
    cursor: pointer;
    display: inline-block;
    color: ${props => props.theme.colors.text.light};
    font: 700 15px/20px ${props => props.theme.fonts.body};
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.xsmall}) {
      display: block;
      &.divider {
        display: none;
      }
    }
  }
`;

const Dates = () => (
  <ContextConsumer>
    {({ calendarDates, toggleDates }) => (
      <Wrapper onClick={toggleDates}>
        <label>
          Dates {' ('}
          {calendarDates.nights}
          {` night${calendarDates.nights > 1 ? 's' : ''})`}
        </label>
        <span>
          {moment(calendarDates.startDate).format('ddd, D MMM, YYYY')}
        </span>
        <span className="divider">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
        <span>{moment(calendarDates.endDate).format('ddd, D MMM, YYYY')}</span>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default Dates;
