import styled from 'styled-components';

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1160px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    justify-content: space-between;
  }
`;

export default Inner;
