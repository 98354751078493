import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Components
import { Wrapper, Inner, Rates, Side } from './files';

// Context
import ContextConsumer from '../../common/contextProvider';

const RatesGrid = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulApartments {
          edges {
            node {
              id
              roomTypeId
              features {
                icon {
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                title
              }
              contentSections {
                title
                imageGallery {
                  id
                  title
                  fluid(maxWidth: 2000) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              seoContent {
                menuTitle
                description {
                  description
                }
                featuredImage {
                  title
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                slug
              }
            }
          }
        }
        allContentfulRooms {
          edges {
            node {
              id
              roomTypeId
              features {
                title
              }
              contentSections {
                title
                imageGallery {
                  id
                  title
                  fluid(maxWidth: 2000) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              seoContent {
                menuTitle
                description {
                  description
                }
                featuredImage {
                  title
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                slug
              }
            }
          }
        }
        contentfulAsset(id: { eq: "71a6ac0c-39e4-5f33-9aa3-911f65939589" }) {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    `}
    render={data => {
      const Apartments = data.allContentfulApartments.edges;
      const Rooms = data.allContentfulRooms.edges;
      const PlaceHolder = data.contentfulAsset;
      return (
        <ContextConsumer>
          {({
            calendarDates,
            getRoomGridRates,
            roomRates,
            adults,
            childs,
            currency,
            contentfulTypeID
          }) => (
            <Wrapper>
              <Inner>
                <Rates
                  Rooms={Rooms}
                  Apartments={Apartments}
                  PlaceHolder={PlaceHolder}
                  calendarDates={calendarDates}
                  getRoomGridRates={getRoomGridRates}
                  roomRates={roomRates}
                  adults={adults}
                  childs={childs}
                  currency={currency}
                  contentfulTypeID={contentfulTypeID}
                />
                <Side />
              </Inner>
            </Wrapper>
          )}
        </ContextConsumer>
      );
    }}
  />
);

export default RatesGrid;
