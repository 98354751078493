import styled from 'styled-components';

const Wrapper = styled.section`
  position: fixed;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 996;
  margin-top: 45px;
  background: ${props => props.theme.colors.primary};
  transition: margin 0.2s;
  &.scrolling {
    margin-top: 0;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
