import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Components
import Loading from '../../../common/loading';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.misc.border};
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.misc.border};
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  @media (max-width: ${props => props.theme.responsive.xsmall}) {
    padding: 30px 0;
  }
  h3 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    margin: 0;
  }
`;

const Right = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  @media (max-width: ${props => props.theme.responsive.xsmall}) {
    padding: 20px 0;
  }
  h2 {
    margin: 0 10px 0 0;
    &.sold {
      margin-top: -15px;
    }
  }
  small {
    font-size: 10px;
    line-height: 1.2;
  }
  button,
  a {
    border: 0;
    height: 40px;
    line-height: 40px;
    margin-left: 30px;
  }
`;

const Price = ({ price, calendarDates, sold, currency }) => {
  const GridRates = price.Grid.Day.slice(0, calendarDates.nights);
  const AvgNight = Math.round(
    GridRates.reduce((a, b) => a + b) / calendarDates.nights
  );

  let RateName;
  if (price.MinNightsGrid.Day[0] === 1) {
    RateName = `Base Rate`;
  }
  if (price.MinNightsGrid.Day[0] > 1) {
    RateName = `${price.MinNightsGrid.Day[0]}+ Night Rate`;
  }

  if (!sold) {
    return (
      <Wrapper>
        <Left>
          <h3>{RateName}</h3>
        </Left>
        <Right>
          <h2>{AvgNight}</h2>
          <small>
            Average
            <br />
            {currency}/night
          </small>
          <ContextConsumer>
            {({ handleSelectBooking }) => (
              <button value={price.RoomId} onClick={handleSelectBooking}>
                Select
              </button>
            )}
          </ContextConsumer>
        </Right>
      </Wrapper>
    );
  }
  if (sold) {
    return (
      <Wrapper>
        <Left>
          <h3>{RateName}</h3>
        </Left>
        <Right>
          <h2 className="sold">...</h2>
          <small>
            Average
            <br />
            {currency}/night
          </small>
          <Link className="button" to="/enquiry/" title="Contact">
            Contact Us
          </Link>
        </Right>
      </Wrapper>
    );
  }
  return <Wrapper>{price === null && <Loading />}</Wrapper>;
};

export default Price;
