import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.div`
  border-left: 1px solid #6c531b;
  padding-left: 1.25rem;
  display: none;
  @media only screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 2;
    float: left;
    margin-right: 1.25rem;
    display: block;
    clear: both;
  }
  label {
    cursor: pointer;
    display: block;
    color: ${props => props.theme.colors.text.dark};
    opacity: 0.6;
    font: 900 12px/14px ${props => props.theme.fonts.body};
    text-transform: uppercase;
  }
  span {
    cursor: pointer;
    display: block;
    color: ${props => props.theme.colors.text.light};
    font: 700 15px/23px ${props => props.theme.fonts.body};
    text-transform: uppercase;
  }
`;

const Guests = () => (
  <ContextConsumer>
    {({ adults, childs, toggleBooking }) => (
      <Wrapper onClick={toggleBooking}>
        <label>Guests</label>
        <span>
          {adults}
          {' Adults, '}
          {childs}
          {' Children'}
        </span>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default Guests;
