import React from 'react';

// Components
import { Wrapper, Inner, Dates, Guests, Edit } from './files';

// Context
import ContextConsumer from '../../common/contextProvider';

const HorizontalDates = () => (
  <ContextConsumer>
    {({ isScrolling }) => (
      <Wrapper className={isScrolling ? 'scrolling' : ''}>
        <Inner>
          <Dates />
          <Guests />
          <Edit />
        </Inner>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default HorizontalDates;
