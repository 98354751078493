import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Components
import Loading from '../../../common/loading';
import { Card } from '.';

// Images
import EmptyState from '../../../../assets/images/icons/emptyState.svg';
import CurrencyInput from './currencyInput';

const Wrapper = styled.div`
  width: 100%;
`;

const Empty = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 40px;
  }
  h3 {
    max-width: 400px;
    text-align: center;
    font-size: 14px;
    line-height: 1.35;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
  }
`;

class Rates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const {
      calendarDates,
      currency,
      getRoomGridRates,
      contentfulTypeID
    } = this.props;
    if (calendarDates !== prevProps.calendarDates) {
      getRoomGridRates();
    }
    if (currency !== prevProps.currency) {
      getRoomGridRates();
    }
    if (contentfulTypeID !== prevProps.contentfulTypeID) {
      getRoomGridRates();
    }
  }

  render() {
    const {
      roomRates,
      calendarDates,
      adults,
      childs,
      Rooms,
      Apartments,
      PlaceHolder,
      currency,
      contentfulTypeID
    } = this.props;

    if (roomRates !== null) {
      if (roomRates.unavailable) {
        return (
          <Empty>
            <img src={EmptyState} alt="Error" />
            <h3>
              Unfortunately, you can only book a maximum of 90 days onlune.
              Please contact us and we&apos;ll give you a quote for your stay.
            </h3>
            <Link className="button" to="/contact/" title="Contact">
              Contact Us
            </Link>
          </Empty>
        );
      }
      if (roomRates.error) {
        return (
          <Empty>
            <img src={EmptyState} alt="Error" />
            <h3>
              Unfortunately, you can only book a maximum of 90 days onlune.
              Please contact us and we&apos;ll give you a quote for your stay.
            </h3>
            <Link className="button" to="/contact/" title="Contact">
              Contact Us
            </Link>
          </Empty>
        );
      }
      if (roomRates[0].RoomRates) {
        // Original Rate Data
        const UnsortedRates = roomRates[0].RoomRates.RoomRates;

        // Remove Room Types That Don't Meet Guest Count
        const FilterGuests = UnsortedRates.filter(
          (e) => e.MaximumGuests >= adults + childs
        );

        // ************************* //
        // ******* AVAILABLE ******* //
        // ************************* //

        // Remove Rates that are sold out or not qualifying
        const FilterAvailable = FilterGuests.filter((e) => e.Grid.Day[0] >= 1);

        // Remove Selected Room Type ID Rates
        const FilterNonSelected = FilterAvailable.filter(
          (e) => e.RoomTypeId !== parseInt(contentfulTypeID, 10)
        );

        // Group RoomId inside RoomTypeId Array
        const GroupedAvailableRoom = _.map(
          _.groupBy(FilterNonSelected, 'RoomTypeId'),
          (rlist, key) => [
            key,
            _.get(rlist, '[0].Name'),
            _.get(rlist, '[0].Grid.Day'),
            rlist.map((roomType) => roomType)
          ]
        );

        // Sort Available Ascending Price
        const AvailableDayAsc = (el) => el[2].reduce((a, b) => a + b);
        const SortedAvailableRates = GroupedAvailableRoom.sort(
          (a, b) => AvailableDayAsc(a) - AvailableDayAsc(b)
        );

        // Convert GroupedRoomTypes to Arrays
        const AvailableMap = SortedAvailableRates;

        // ******************************** //
        // ******* ISOLATE SELECTED ******* //
        // ******************************** //

        // Isolate Selected Room Type ID Rates
        const FilterSelected = FilterAvailable.filter(
          (e) => e.RoomTypeId === parseInt(contentfulTypeID, 10)
        );

        // Group RoomId inside RoomTypeId Array
        const GroupedSelectedRoom = _.map(
          _.groupBy(FilterSelected, 'RoomTypeId'),
          (rlist, key) => [
            key,
            _.get(rlist, '[0].Name'),
            _.get(rlist, '[0].Grid.Day'),
            rlist.map((roomType) => roomType)
          ]
        );

        // Convert GroupedRoomTypes to Arrays
        const SelectedMap = GroupedSelectedRoom;

        // ************************************************* //
        // ******* ISOLATE SELECTED INCORRECT GUESTS ******* //
        // ************************************************* //

        // Isolate Selected Room Type ID Rates with incorrect guest count
        // Remove Room Types That Don't Meet Guest Count
        const FilterSelectedPlusGuests = UnsortedRates.filter(
          (e) => e.MaximumGuests < adults + childs
        );

        // Remove Rates that are sold out or not qualifying
        const FilterSelectedPlusGuestsAvailable = FilterSelectedPlusGuests.filter(
          (e) => e.Grid.Day[0] >= 1
        );

        // Remove Rates that are not selected
        const FilterSelectedIDPlusGuests = FilterSelectedPlusGuestsAvailable.filter(
          (e) => e.RoomTypeId === parseInt(contentfulTypeID, 10)
        );

        // Group RoomId inside RoomTypeId Array
        const GroupedSelectedPlusGuests = _.map(
          _.groupBy(FilterSelectedIDPlusGuests, 'RoomTypeId'),
          (rlist, key) => [
            key,
            _.get(rlist, '[0].Name'),
            _.get(rlist, '[0].Grid.Day'),
            rlist.map((roomType) => roomType)
          ]
        );

        // Convert GroupedRoomTypes to Arrays
        const SelectedPlusGuestsMap = GroupedSelectedPlusGuests;

        // ************************ //
        // ******* SOLD OUT ******* //
        // ************************ //

        // Get Rates that are sold out
        const FilterSoldOut = FilterGuests.filter((e) => e.Grid.Day[0] === 0);

        // Group RoomId inside RoomTypeId Array
        const GroupedSoldRooms = _.map(
          _.groupBy(FilterSoldOut, 'RoomTypeId'),
          (rlist, key) => [
            key,
            _.get(rlist, '[0].Name'),
            _.get(rlist, '[0].Grid.Day'),
            rlist.map((roomType) => roomType)
          ]
        );

        // Convert GroupedRoomTypes to Arrays
        const SoldMap = GroupedSoldRooms;

        // ***************************************** //
        // ******* ISOLATE SELECTED SOLD OUT ******* //
        // ***************************************** //

        // Isolate Selected Room Type ID Rates
        const FilterSelectedSold = FilterSoldOut.filter(
          (e) => e.RoomTypeId === parseInt(contentfulTypeID, 10)
        );

        // Group RoomId inside RoomTypeId Array
        const GroupedSelectedSoldRoom = _.map(
          _.groupBy(FilterSelectedSold, 'RoomTypeId'),
          (rlist, key) => [
            key,
            _.get(rlist, '[0].Name'),
            _.get(rlist, '[0].Grid.Day'),
            rlist.map((roomType) => roomType)
          ]
        );

        // Convert GroupedRoomTypes to Arrays
        const SelectedSoldMap = GroupedSelectedSoldRoom;

        // If there is no results
        if (roomRates.length === 0) {
          return (
            <Empty>
              <img src={EmptyState} alt="No rooms found" />
              <h3>
                Unfortunately, we appear to be having a technical issue. Please
                contact us and we&apos;ll give you a quote for your stay.
              </h3>
              <Link className="button" to="/contact/" title="Contact">
                Contact Us
              </Link>
            </Empty>
          );
        }

        // If guest count is greater than room counts
        if (childs + adults > 5) {
          return (
            <Empty>
              <img src={EmptyState} alt="No rooms available" />
              <h3>
                Unfortunately, we do not have rooms large enough for your party.
                Please contact us and we&apos;ll give you a quote for multiple
                rooms.
              </h3>
              <Link className="button" to="/contact/" title="Contact">
                Contact Us
              </Link>
            </Empty>
          );
        }
        if (roomRates.length !== 0) {
          return (
            <Wrapper>
              <CurrencyInput />
              <>
                {/* Selected Rate */}
                {SelectedMap.map((i, index) => (
                  <Card
                    childs={childs}
                    adults={adults}
                    selected
                    key={index}
                    roomType={i}
                    calendarDates={calendarDates}
                    Apartments={Apartments}
                    Rooms={Rooms}
                    PlaceHolder={PlaceHolder}
                    currency={currency}
                  />
                ))}
              </>
              <>
                {/* Selected Rate too many guests */}
                {SelectedPlusGuestsMap.map((i, index) => (
                  <Card
                    childs={childs}
                    adults={adults}
                    tooMany
                    selected
                    key={index}
                    roomType={i}
                    calendarDates={calendarDates}
                    Apartments={Apartments}
                    Rooms={Rooms}
                    PlaceHolder={PlaceHolder}
                    currency={currency}
                  />
                ))}
              </>
              <>
                {/* Selected Sold Rate */}
                {SelectedSoldMap.map((i, index) => (
                  <Card
                    childs={childs}
                    adults={adults}
                    sold
                    selected
                    key={index}
                    roomType={i}
                    calendarDates={calendarDates}
                    Apartments={Apartments}
                    Rooms={Rooms}
                    PlaceHolder={PlaceHolder}
                    currency={currency}
                  />
                ))}
              </>
              <>
                {/* Available Rates */}
                {AvailableMap.map((i, index) => (
                  <Card
                    childs={childs}
                    adults={adults}
                    key={index}
                    roomType={i}
                    calendarDates={calendarDates}
                    Apartments={Apartments}
                    Rooms={Rooms}
                    PlaceHolder={PlaceHolder}
                    currency={currency}
                  />
                ))}
              </>
              <>
                {/* Sold Rates */}
                {SoldMap.map((i, index) => (
                  <Fragment key={index}>
                    {i[0] !== contentfulTypeID ? (
                      <Card
                        childs={childs}
                        adults={adults}
                        sold
                        roomType={i}
                        calendarDates={calendarDates}
                        Apartments={Apartments}
                        Rooms={Rooms}
                        PlaceHolder={PlaceHolder}
                        currency={currency}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </>
            </Wrapper>
          );
        }
      }
    }

    return <Wrapper>{roomRates === null && <Loading />}</Wrapper>;
  }
}

export default Rates;
