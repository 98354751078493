import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 1.75em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: ${props => props.theme.colors.text.dark};
    margin-right: 1em;
  }
  .input-wrap {
    select {
      display: block;
      margin: 0;
      padding: 0 10px;
      width: 100%;
      height: 40px;
      outline: 0;
      border: none;
      border-radius: 0;
      background: ${props => props.theme.colors.background.light};
      color: ${props => props.theme.colors.text.normal};
      font-weight: 700;
      font-size: 13px;
    }
  }
`;

const CurrencyInput = () => (
  <ContextConsumer>
    {({ currency, handleCurrencyChange }) => {
      return (
        <Wrapper>
          <div className="label">Currency</div>
          <div className="input-wrap">
            <select value={currency} onChange={handleCurrencyChange}>
              <option value="AUD">Australian Dollar (AUD)</option>
              <option value="AED">Arab Emirates Dirham (AED)</option>
              <option value="BDT">Bangladeshi Taka (BDT)</option>
              <option value="BRL">Brazilian Real (BRL)</option>
              <option value="BGN">Bulgarian Lev (BGN)</option>
              <option value="CAD">Canadian Dollar (CAD)</option>
              <option value="CNY">Chinese Yuan (CNY)</option>
              <option value="EUR">Euro (EUR)</option>
              <option value="FJD">Fiji Dollar (FJD)</option>
              <option value="GHS">Ghana Cedis (GHS)</option>
              <option value="HKD">Hong Kong Dollar (HKD)</option>
              <option value="INR">India Rupees (INR)</option>
              <option value="IDR">Indonesian Rupiah (IDR)</option>
              <option value="JPY">Japanese Yen (JPY)</option>
              <option value="LVL">Latvian Lats (LVL)</option>
              <option value="MYR">Malaysian Ringgit (MYR)</option>
              <option value="MUR">Mauritian Rupee (MUR)</option>
              <option value="NZD">New Zealand Dollar (NZD)</option>
              <option value="PGK">Papua New Guinean Kina (PGK)</option>
              <option value="PEN">Peruvian Soles (PEN)</option>
              <option value="PHP">Philippine Peso (PHP)</option>
              <option value="GBP">Pound Sterling (GBP)</option>
              <option value="RUB">Russian Ruble (RUB)</option>
              <option value="SAR">Saudi Arabian Riyal (SAR)</option>
              <option value="SGD">Singapore Dollar (SGD)</option>
              <option value="LKR">Sri Lanka Rupees (LKR)</option>
              <option value="WST">Samoan Tālā (WST)</option>
              <option value="SBD">Solomon Island Dollar (SBD)</option>
              <option value="ZAR">South Africa Rand (ZAR)</option>
              <option value="KRW">South Korean Won (KRW)</option>
              <option value="THB">Thai Baht (THB)</option>
              <option value="TOP">Tongan Pa&apos;anga (TOP)</option>
              <option value="TVD">Tuvaluan Dollar (TVD)</option>
              <option value="USD">US Dollar (USD)</option>
              <option value="VUV">Vanuatu Vatu (VUV)</option>
              <option value="VND">Vietnamese Dong (VND)</option>
            </select>
          </div>
        </Wrapper>
      );
    }}
  </ContextConsumer>
);

export default CurrencyInput;
