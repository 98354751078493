import React from 'react';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../common/contextProvider';

const Wrapper = styled.div`
  @media only screen and (min-width: ${props => props.theme.responsive.small}) {
    justify-self: flex-end;
  }
  button {
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.theme.colors.text.light};
    background: transparent;
    min-width: 180px;
    height: 40px;
    line-height: 38px;
    border-radius: 4px;
    border: solid 2px ${props => props.theme.colors.text.light};
    text-align: center;
    &:hover {
      background: ${props => props.theme.colors.background.dark};
      border-color: ${props => props.theme.colors.background.dark};
    }
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      font-size: 13px;
      min-width: 130px;
    }
    &.text-button {
      display: block;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.xsmall}) {
        display: none;
      }
    }
    &.icon-button {
      display: none;
      border: none;
      min-width: auto;
      padding: 0;
      &:hover {
        background: transparent;
        border-color: transparent;
      }
      @media only screen and (max-width: ${props =>
          props.theme.responsive.xsmall}) {
        display: block;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`;

const Edit = () => (
  <ContextConsumer>
    {({ toggleBooking }) => (
      <Wrapper>
        <button className="text-button" onClick={toggleBooking}>
          Edit Search
        </button>
        <button className="icon-button" onClick={toggleBooking}>
          <img
            alt="Edit Search"
            src="data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMwMCAzMDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwMCAzMDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMTQ5Ljk5NiwwQzY3LjE1NywwLDAuMDAxLDY3LjE2MSwwLjAwMSwxNDkuOTk3UzY3LjE1NywzMDAsMTQ5Ljk5NiwzMDBzMTUwLjAwMy02Ny4xNjMsMTUwLjAwMy0xNTAuMDAzICAgIFMyMzIuODM1LDAsMTQ5Ljk5NiwweiBNMjIxLjMwMiwxMDcuOTQ1bC0xNC4yNDcsMTQuMjQ3bC0yOS4wMDEtMjguOTk5bC0xMS4wMDIsMTEuMDAybDI5LjAwMSwyOS4wMDFsLTcxLjEzMiw3MS4xMjYgICAgbC0yOC45OTktMjguOTk2TDg0LjkyLDE4Ni4zMjhsMjguOTk5LDI4Ljk5OWwtNy4wODgsNy4wODhsLTAuMTM1LTAuMTM1Yy0wLjc4NiwxLjI5NC0yLjA2NCwyLjIzOC0zLjU4MiwyLjU3NWwtMjcuMDQzLDYuMDMgICAgYy0wLjQwNSwwLjA5MS0wLjgxNywwLjEzNS0xLjIyNCwwLjEzNWMtMS40NzYsMC0yLjkxLTAuNTgxLTMuOTczLTEuNjQ3Yy0xLjM2NC0xLjM1OS0xLjkzMi0zLjMyMi0xLjUxMi01LjIwM2w2LjAyNy0yNy4wMzUgICAgYzAuMzQtMS41MTcsMS4yODYtMi43OTgsMi41NzgtMy41ODJsLTAuMTM3LTAuMTM3TDE5Mi4zLDc4Ljk0MWMxLjY3OC0xLjY3NSw0LjQwNC0xLjY3NSw2LjA4MiwwLjAwNWwyMi45MjIsMjIuOTE3ICAgIEMyMjIuOTgyLDEwMy41NDEsMjIyLjk4MiwxMDYuMjY3LDIyMS4zMDIsMTA3Ljk0NXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0ZGRkZGRiIgZGF0YS1vbGRfY29sb3I9IiNmZmZmZmYiPjwvcGF0aD4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+"
          />
        </button>
      </Wrapper>
    )}
  </ContextConsumer>
);

export default Edit;
