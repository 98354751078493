import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HorizontalDates from '../components/global/horizontalDates';
import RatesGrid from '../components/global/ratesGrid';

const BookingPage = ({ data }) => {
  const { seoContent } = data.contentfulPages;
  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HorizontalDates />
      <RatesGrid />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "a3c0361c-1124-5a5a-a223-44ff88ffa118" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

export default BookingPage;
