import styled from 'styled-components';

const Inner = styled.div`
  display: flex;
  max-width: 1160px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
  }
`;

export default Inner;
