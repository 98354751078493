import React from 'react';
import ReactModal from 'react-modal';

// Components
import { Slider } from './files';

// Context
import ContextConsumer from '../../contextProvider';

const ModalStyles = {
  overlay: {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: `100%`,
    minWidth: `100%`,
    overflowY: `auto`,
    zIndex: `1002`,
    background: `#ffffff`
  },
  content: {
    background: `transparent`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
    borderRadius: 0
  }
};

ReactModal.setAppElement('#___gatsby');

const BookingModal = ({ images }) => (
  <ContextConsumer>
    {({ showModal, toggleBookingModalClose, galleryid }) => (
      <ReactModal
        isOpen={showModal}
        contentLabel="Image Gallery"
        onRequestClose={toggleBookingModalClose}
        shouldCloseOnOverlayClick
        style={ModalStyles}
      >
        <Slider
          images={images}
          galleryid={galleryid}
          toggleBookingModalClose={toggleBookingModalClose}
        />
      </ReactModal>
    )}
  </ContextConsumer>
);

export default BookingModal;
